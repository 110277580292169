import {Flex, Menu, type MenuProps} from 'antd';
import {Link, UIMatch, useMatches} from 'react-router-dom';
import {
    ApartmentOutlined,
    CustomerServiceOutlined,
    DeploymentUnitOutlined,
    IdcardOutlined,
    GlobalOutlined,
    LikeOutlined,
    SettingOutlined,
    TeamOutlined,
    ToolOutlined,
    TrophyOutlined,
    UserOutlined,
} from '@ant-design/icons';

import type {Person, Team} from 'types';
import {isPersonSystem, trimSlashes} from 'utils';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {usePersonTeams} from 'hooks/useTeams';

function createMyTeamItems(currentUser: Person | null, teams: Team[]) {
    if (!currentUser) {
        return undefined;
    }

    if (isPersonSystem(currentUser)) {
        return {
            key: 'teams',
            label: <Link to="/teams">All Teams</Link>,
            icon: <TeamOutlined/>
        };
    }

    const teamItems = teams.map(team => {
        return {
            key: `teams/${team.id}`,
            label: <Link to={`/teams/${team.id}`}>{team.name}</Link>,
            icon: <TeamOutlined/>
        };
    }) as MenuProps['items'];

    if (teamItems && teamItems.length === 0) {
        teamItems.push({
            key: `no-teams`,
            label: <span>No Teams Found.</span>,
            disabled: true,
        })
    }

    return {
        key: 'my-teams',
        label: 'My Teams',
        type: 'group',
        children: teamItems
    };
}

function createItems(currentUser: Person | null, teams: Team[]) {

    const teamItems = teams.map(team => {
        return {
            key: `team-${team.id}`,
            label: <Link to="/teams/">{team.name}</Link>,
            icon: <TeamOutlined/>
        };
    }) as MenuProps['items'];

    if (teamItems && teamItems.length === 0) {
        teamItems.push({
            key: `no-teams`,
            label: <span>No Teams Found.</span>,
            disabled: true,
        })
    }

    const mainGroupChildren = [
        createMyTeamItems(currentUser, teams),
        {
            type: 'divider',
            style: {
                marginTop: '16px',
                marginBottom: '16px',
            }
        },
        {
            key: 'orgChart',
            label: <Link to="/orgChart">Org Chart</Link>,
            icon: <ApartmentOutlined/>
        },
        {
            key: 'zoneChart',
            label: <Link to="/zoneChart">Zone Chart</Link>,
            icon: <DeploymentUnitOutlined/>
        },
        {
            type: 'divider',
            style: {
                marginTop: '16px',
                marginBottom: '16px',
            },
        },
        {
            key: 'zones',
            label: <Link to="/zones">Zones</Link>,
            icon: <GlobalOutlined/>
        }
    ];

    if (!isPersonSystem(currentUser)) {
        mainGroupChildren.push({
            key: 'teams',
            label: <Link to="/teams">All Teams</Link>,
            icon: <TeamOutlined/>
        });
    }

    mainGroupChildren.push({
        type: 'divider',
        style: {
            marginTop: '16px',
            marginBottom: '16px',
        },
    });

    if (isPersonSystem(currentUser)) {
        mainGroupChildren.push({
            key: 'tables',
            label: 'Tables',
            type: 'group',
            children: [
                {
                    key: 'people',
                    label: <Link to="/people">People</Link>,
                    icon: <UserOutlined/>
                },
                {
                    key: 'roles',
                    label: <Link to="/roles">Roles</Link>,
                    icon: <IdcardOutlined/>
                },
                {
                    key: 'goals',
                    label: <Link to="/goals">Goals</Link>,
                    icon: <TrophyOutlined/>
                },
                {
                    key: 'decisions',
                    label: <Link to="/decisions">Decisions</Link>,
                    icon: <LikeOutlined/>
                },
                {
                    key: 'service-agreements',
                    label: <Link to="/service-agreements">Service Agreements</Link>,
                    icon: <CustomerServiceOutlined/>
                }
            ],
        });
    }

    return [
        {
            key: 'mainGroup',
            type: 'group',
            children: mainGroupChildren
        }
    ] as MenuProps['items'];
}

function defaultSelectedKey(matches: UIMatch[]) {
    const lastRoute = matches[matches.length - 1];
    return trimSlashes(lastRoute.pathname);
}

const SiderMenu = () => {
    const matches = useMatches();
    const selectedKey = defaultSelectedKey(matches);
    const currentUser = useCurrentUser();

    const myTeams = usePersonTeams(currentUser);
    const menuItems = createItems(currentUser, myTeams);

    if (currentUser && menuItems) {
        if (['admin', 'system'].includes(currentUser?.userType)) {
            const children: any[] = [
                {
                    type: 'divider',
                    style: {
                        marginTop: '16px',
                        marginBottom: '16px',
                    },
                },
                {
                    key: 'settings',
                    label: <Link to="/settings">Settings</Link>,
                    icon: <SettingOutlined/>
                },
            ];

            if (currentUser.userType === 'system') {
                children.push({
                    key: 'admin',
                    label: <Link to="/admin">Admin (System Only)</Link>,
                    icon: <ToolOutlined/>
                })
            }

            menuItems.push(
                {
                    key: 'adminGroup',
                    type: 'group',
                    children: children
                }
            );
        }
    }

    return (
        <Flex vertical style={{borderRight: 0, flexGrow: 1}}>
            <Menu
                style={{borderRight: 0, flexGrow: 1, display: 'flex', flexDirection: 'column'}}
                mode="inline"
                items={menuItems}
                defaultSelectedKeys={selectedKey ? [selectedKey] : undefined}
            />
        </Flex>
    );
}

export default SiderMenu;
