import styles from './styles.module.scss';
import {useThemeMode} from 'hooks/useThemeMode';

const TeamGraphLegend = () => {
    const mode = useThemeMode();
    return (
        <div className={styles.legend}>
            <div>
                <span className={styles.dot} style={{
                    backgroundColor: styles.teamColorFill,
                    borderColor: styles.teamColorStroke
                }}/><span>Team</span>
            </div>
            <div>
                <span className={styles.dot} style={{
                    backgroundColor: styles.roleColorFill,
                    borderColor: styles.roleColorStroke
                }}/><span>Role (filled)</span>
            </div>
            <div>
                <span className={styles.dot} style={{
                    backgroundColor: mode === 'dark' ? styles.darkBb : styles.lightBg,
                    borderColor: styles.roleColorStroke
                }}/><span>Role (unfilled)</span>
            </div>
        </div>
    );
}

export default TeamGraphLegend;
