import React from 'react';

import type {Person} from 'types';
import {map} from 'utils';
import OrgChartComponent, {UserDatum} from './OrgChartComponent';
import {usePeople} from 'hooks/usePeople';

function buildData(people: Person[]) {
    const userMapping = map(people);

    const result: Record<string, UserDatum> = {};
    for (const person of people) {
        if (person.reportsTo) {
            const reportsTo = userMapping[person.reportsTo.id];

            result[person.id] = {
                id: person.id,
                parentId: reportsTo.id,
                name: person.name,
                title: person.title ? person.title : '',
                imageUrl: person.picture_medium
            };

            if (!result[reportsTo.id]) {
                // This may be overwritten if found later...
                result[reportsTo.id] = {
                    id: reportsTo.id,
                    parentId: "",
                    name: reportsTo.name,
                    title: reportsTo.title ? reportsTo.title : '',
                    imageUrl: reportsTo.picture_medium
                };
            }
        }
    }
    return Object.values(result);
}

const OrganizationPage = () => {
    const people = usePeople();
    return (
        <OrgChartComponent data={buildData(people)} />
    );
}

export default OrganizationPage;
