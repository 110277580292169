import {Card, Select} from 'antd';
import {useSetSetting, useSettings} from 'hooks/useSettings';
import {usePersonTeams} from 'hooks/useTeams';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {isPersonSystem} from 'utils';

const DefaultHomePageCard = () => {
    const currentUser = useCurrentUser();
    const teams = usePersonTeams(currentUser);

    const settings = useSettings();
    const setSettings = useSetSetting();
    const home = settings['home'] || 'first-team';

    const options = [
        {label: 'Org Chart', value: 'org-chart'},
        {label: 'Zone Chart', value: 'zone-chart'}
    ];

    if (isPersonSystem(currentUser)) {
        options.unshift({label: 'All Teams', value: 'all-teams'});
    } else {
        options.unshift({label: 'First Team', value: 'first-team'});
        options.push(...teams.map(team => ({label: team.name, value: team.id})));
    }

    function onChange(value: string) {
        setSettings('home', value);
    }

    return (
        <Card title={'Default Home Page'} size={'small'}>
            <Select
                value={home}
                onChange={onChange}
                options={options}
                style={{width: '100%', maxWidth: '200px'}}
            />
        </Card>
    );
}

export default DefaultHomePageCard;
