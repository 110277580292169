import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {App, Button, Card, Select, Typography} from 'antd';

import {asOptions, getErrorMessage} from 'utils';
import type {Organization, Person} from 'types';
import apiClient from 'api';
import {LoginFormResponse} from 'pages/auth/login/LoginForm';
import {current} from 'loader';

const ImpersonateCard = (props: {
    org: Organization
    busy: boolean,
    setBusy: (value: boolean) => void,
    people: Person[]
}) => {
    const {message} = App.useApp();
    const navigate = useNavigate();
    const [personId, setPersonId] = useState<string>();

    async function impersonate() {
        if (personId) {
            props.setBusy(true);
            try {
                const data = await apiClient.post<LoginFormResponse>(
                    '/auth/impersonate', {
                        person_id: personId,
                        org_id: props.org!.id
                    }
                );
                apiClient.setToken(data.token.access_token);
                await current();
                navigate('/');
            } catch (e) {
                message.error(getErrorMessage(e));
            } finally {
                props.setBusy(false);
            }
        }
    }

    return (
        <Card style={{width: '100%', maxWidth: '400px'}}>
            <Typography.Paragraph>
                <Select
                    showSearch={true}
                    onChange={setPersonId}
                    style={{width: '100%', maxWidth: '400px'}}
                    options={asOptions(props.people)}
                    filterOption={(input, option) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                />
                <small>Select a person to login as them.</small>
            </Typography.Paragraph>
            <Button loading={props.busy} disabled={!personId||props.busy} onClick={impersonate}>Impersonate</Button>
        </Card>
    );
}

export default ImpersonateCard;
