import {CSSProperties} from 'react';

import styles from './styles.module.scss';
import {FDGNode} from './types';
import {canvasToElement} from './utils';

import {useThemeMode} from 'hooks/useThemeMode';

const FDGTooltip = (props: {
    canvas: HTMLCanvasElement | null,
    node: FDGNode|undefined,
    onTooltipText: (node: FDGNode|undefined) => string|null|undefined;
}) => {
    const mode = useThemeMode();
    if (!props.canvas || !props.node) return null;

    const text = props.onTooltipText(props.node);
    if (!text) return null;

    const radius = props.node.radius ? props.node.radius : 0;
    const p = canvasToElement(props.canvas, props.node.x || 0, props.node.y || 0);

    const yOffset = canvasToElement(props.canvas, radius, 0)[0] + 5 /* carat */ + 2 /* lineWidth/2 */;

    const style = {
        left: p[0],
        top: p[1],
        transform: `translate(-50%, -100%) translateY(-${yOffset}px)`,
    } as CSSProperties;

    return (
        <div className={`${styles.tooltip} ${mode === 'dark' ? styles.dark : undefined}`} style={style}><span>{text}</span></div>
    );
}

export default FDGTooltip;
