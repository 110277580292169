import type {Zone} from 'types';

import {useTemplate} from 'hooks/useTemplates';

import Object from 'components/Object';
import MarkdownDiv from 'components/MarkdownDiv';
import DrawerDescription from 'components/drawers/common/DrawerDescription';
import FieldDrawerDescription from 'components/drawers/common/FieldDrawerDescription';


const ZoneDrawerDisplay = (props: {
    zone: Zone
}) => {
    const template = useTemplate(props.zone?.template.id, props.zone?.type);
    const templateFields = template ? template.fields : [];

    return (
        <>
            <DrawerDescription title={'Boundary'}>
                <MarkdownDiv markdown={props.zone.boundary} />
            </DrawerDescription>
            {templateFields.map((field, i) => {
                return (
                    <FieldDrawerDescription
                        key={`${field.type}=${field.name}-${i}`}
                        obj={props.zone}
                        field={field}
                    />
                );
            })}
            <Object style={{margin: '2em 0'}} object={props.zone}/>
        </>
    );
}

export default ZoneDrawerDisplay;
