import {useState} from 'react';
import {App, Button, Card, Select, Space, Typography} from 'antd';

import type {Organization, Person, UserType} from 'types';
import {asOptions, getDispatchError, hasDispatchError} from 'utils';

import {AppDispatch, useAppDispatch} from 'store';
import UserTypeSelect from './UserTypeSelect';
import {updatePerson} from 'features/personSlice';
import {usePerson} from 'hooks/usePeople';

const UserTypeCard = (props: {
    org: Organization
    busy: boolean,
    setBusy: (value: boolean) => void,
    people: Person[]
}) => {
    const {message} = App.useApp();

    const dispatch: AppDispatch = useAppDispatch();

    const [personId, setPersonId] = useState<string>();
    const [userType, setUserType] = useState<UserType>('regular');
    const person = usePerson(personId);

    async function onClick() {
        if (person) {
            const newPerson = {...person, userType} as Person;
            const res = await dispatch(updatePerson(newPerson));
            if (hasDispatchError(res)) {
                return message.error(getDispatchError(res));
            }
            setPersonId(undefined);
            return message.success(`Person ${newPerson.email} updated to '${userType}'`);
        }
    }

    return (
        <Card title={'Set User Type'} size={'small'}>
            <Typography.Paragraph>
                <Space direction={'vertical'}>
                    <Select
                        value={personId}
                        showSearch={true}
                        onChange={setPersonId}
                        style={{width: '100%', maxWidth: '400px'}}
                        options={asOptions(props.people)}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                    <UserTypeSelect
                        userType={userType}
                        setUserType={setUserType}
                    />
                    <small>Set the user type for this person in this organization.</small>
                </Space>
            </Typography.Paragraph>
            <Button loading={props.busy} disabled={!personId || props.busy} onClick={onClick}>Set User Type</Button>
        </Card>
    );
}

export default UserTypeCard;
