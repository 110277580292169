import type {MouseEvent} from 'react';
import {FDGNode} from './types';
import {rgbToHex} from '../../../utils';

export function defaultRadius(width: number, nodeCount: number, radius?: number) {
    //return radius ? radius : width / Math.max(4*nodeCount, 1);
    return 20;
}


export function canvasPointer(canvas: HTMLCanvasElement, event: MouseEvent): [number, number]  {
    const rect = canvas.getBoundingClientRect();

    const x = ((event.clientX - rect.x) / rect.width) * canvas.width;
    const y = (((event.clientY - rect.y) / rect.height) * canvas.height) + canvas.height;

    return [x, y];
}

export function canvasToElement(canvas: HTMLCanvasElement, canvasX: number, canvasY: number): [number, number]  {
    const rect = canvas.getBoundingClientRect();

    const x = canvasX / canvas.width * rect.width;
    const y = canvasY / canvas.height * rect.height;

    return [x, y];
}

export function getNodeFromEvent(event: MouseEvent, shadowContext: CanvasRenderingContext2D, colorMap: Map<string, FDGNode>) {
    const p = canvasPointer(shadowContext.canvas, event);
    if (p[0] === Infinity || p[1] === Infinity) {
        return undefined;
    }

    const imageData = shadowContext.getImageData(p[0], p[1], 1, 1).data;
    const hex = rgbToHex(imageData[0], imageData[1], imageData[2]);
    return colorMap.get(hex);
}
