import {configureStore, createAction} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';

import themeModeSlice from 'features/themeModeSlice';
import currentSlice from 'features/currentUserSlice';
import roleSlice from 'features/roleSlice';
import teamSlice from 'features/teamSlice';
import templateSlice from 'features/templateSlice';
import decisionSlice from 'features/decisionSlice';
import goalSlice from 'features/goalSlice';
import riskSlice from 'features/riskSlice';
import peopleSlice from 'features/personSlice';
import orgSlice from 'features/orgSlice';
import zoneSlice from 'features/zoneSlice';
import serviceAgreementSlice from 'features/serviceAgreementSlice';
import settingsSlice from 'features/settingsSlice';

export const LOGOUT_ACTION = createAction('logout')
export const CLEAR_ORG_ACTION = createAction('clear');

const store = configureStore({
    reducer: {
        settingsSlice: settingsSlice,
        themeMode: themeModeSlice,
        current: currentSlice,
        org: orgSlice,

        decisions: decisionSlice,
        goals: goalSlice,
        risks: riskSlice,
        roles: roleSlice,
        serviceAgreements: serviceAgreementSlice,
        teams: teamSlice,
        templates: templateSlice,
        people: peopleSlice,
        zones: zoneSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();

export default store;
