import {Space} from 'antd';
import {usePeople} from 'hooks/usePeople';
import {useOrg} from 'hooks/useOrg';
import {useState} from 'react';
import ImpersonateCard from './ImpersonateCard';
import UserTypeCard from './UserTypeCard';
import CreateUserCard from './CreatePersonCard';

const AdminPage = () => {
    const [busy, setBusy] = useState(false);

    const people = usePeople();
    const org = useOrg();
    if (!org) return null;

    return (
        <div>
            <p>This page is for system admins only. It will mostly be for creating/managing organizations.</p>
            <p>{process.env['REACT_APP_API_URL']}</p>
            <Space direction={'vertical'} size={'large'}>
                <CreateUserCard
                    org={org}
                    busy={busy}
                    setBusy={setBusy}
                />
                <UserTypeCard
                    org={org}
                    busy={busy}
                    setBusy={setBusy}
                    people={people}
                />
                <ImpersonateCard
                    org={org}
                    busy={busy} setBusy={setBusy}
                    people={people}
                />
            </Space>
        </div>
    );
}

export default AdminPage;
