import {Navigate, RouteObject} from 'react-router-dom';

import {current} from './loader';
import ErrorBoundary from 'components/ErrorBoundary';
import MainPage from 'components/layout/MainPage';

import LoginPage from 'pages/auth/login/LoginPage';
import ForgotPasswordPage from 'pages/auth/forgotPassword/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/resetPassword/ResetPasswordPage';

import HomePage from 'pages/home/HomePage';
import ZoneChartPage from 'pages/zones/ZoneChartPage';

import PeopleListPage from 'pages/tables/people/PeopleListPage';
import PersonDetailPage from 'pages/tables/people/PersonDetailPage';

import ZoneDetailPage from 'pages/tables/zones/ZoneDetailPage';
import ZonesListPage from 'pages/tables/zones/ZonesListPage';

import TeamsListPage from 'pages/teams/TeamsListPage';
import TeamDetailPage from 'pages/teams/TeamDetailPage';

import RolesListPage from 'pages/tables/roles/RolesListPage';
import RoleDetailPage from 'pages/tables/roles/RoleDetailPage';

import GoalsListPage from 'pages/tables/goals/GoalsListPage';
import GoalDetailPage from 'pages/tables/goals/GoalDetailPage';

import DecisionDetailPage from 'pages/tables/decisions/DecisionDetailPage';
import DecisionsListPage from 'pages/tables/decisions/DecisionsListPage';

import ServiceAgreementDetailPage from 'pages/tables/serviceAgreements/ServiceAgreementDetailPage';
import ServiceAgreementsListPage from 'pages/tables/serviceAgreements/ServiceAgreementsListPage';

import SettingsPage from 'pages/settings/SettingsPage';
import TemplateDetailPage from 'pages/settings/TemplateDetailPage';

import AdminPage from 'pages/admin/AdminPage';
import CreateTemplatePage from 'pages/settings/edit/CreateTemplatePage';
import EditTemplatePage from 'pages/settings/edit/EditTemplatePage';
import ProfilePage from 'pages/profile/ProfilePage';
import OrganizationPage from 'pages/org/OrganizationPage';

/*
const router = [
    {
        path: '/auth',
        element: <AuthPage/>
    },
    {
        path: '',
        element: <MainPage/>,
        errorElement: <ErrorBoundary/>,
        children: [
            {
                path: '/',
                element: <DashboardPage/>,
                handle: {
                    title: 'My Dashboard',
                },
            },
            {
                path: '/org',
                element: <OrganizationPage/>,
                loader: org,
                handle: {
                    title: 'Organization',
                },
            },
            {
                path: '/zones',
                element: <ZonePage/>,
                handle: {
                    title: 'Zones',
                },
            },
            {
                path: '/network',
                element: <NetworkPage/>,
                handle: {
                    title: 'The Network',
                },
            },
            {
                path: '/teams',
                children: [
                    {
                        path: '',
                        element: <TeamsListPage/>,
                        handle: {
                            title: 'Teams',
                        },
                    },
                    {
                        path: ':teamId',
                        element: <TeamDetailPage/>,
                    }
                ]
            },
            {
                path: '/goals',
                children: [
                    {
                        path: '',
                        element: <GoalsListPage/>,
                        loader: goals,
                        handle: {
                            title: 'Goals',
                        },
                    },
                    {
                        path: ':goalId',
                        element: <GoalDetailPage/>,
                        loader: goalDetailLoader,
                    }
                ]
            },
            {
                path: '/agreements',
                children: [
                    {
                        path: '',
                        element: <AgreementsListPage/>,
                        handle: {
                            title: 'Agreements',
                        },
                    },
                    {
                        path: ':agreementId',
                        element: <AgreementDetailPage/>,
                        loader: agreementDetailLoader,
                    }
                ]
            },
            {
                path: '/users',
                children: [
                    {
                        path: '',
                        element: <UsersListPage/>,
                        loader: usersListLoader,
                        handle: {
                            title: 'Users',
                        },
                    },
                    {
                        path: ':userId',
                        element: <UserDetailPage/>,
                        loader: userDetailLoader,
                    }
                ]
            },
            {
                path: '/templates',
                children: [
                    {
                        path: 'teams',
                        element: <TemplatesListPage objectType={'team'}/>,
                        handle:
                            {
                                title: 'Team Templates',
                            }
                        ,
                    },
                    {
                        path: 'users',
                        element:
                            <TemplatesListPage objectType={'person'} />,
                        handle: {
                            title: 'User Templates',
                        }
                    },
                    {
                        path: 'roles',
                        element:
                            <TemplatesListPage objectType={'role'} />,
                        handle: {
                            title: 'Team Templates',
                        }
                    },
                    {
                        path: 'role-assignments',
                        element:
                            <TemplatesListPage objectType={'role_assignment'} />,
                        handle: {
                            title: 'Role Assignment Templates',
                        }
                    },
                    {
                        path: 'agreements',
                        element:
                            <TemplatesListPage objectType={'agreement'} />,
                        handle: {
                            title: 'Agreement Templates',
                        }
                    },
                    {
                        path: ':templateId',
                        element:
                            <TemplateDetailPage />,
                        loader:
                        templateDetailLoader,
                    }
                ]
            },
            {
                path: '*',
                element:
                    <Navigate to={'/'} replace/>,
            }
        ]
    }
] as RouteObject[];
*/

const router = [
    {
        path: '/login',
        element: <LoginPage/>,
    },
    {
        path: '/forgot-password',
        element: <ForgotPasswordPage />,
    },
    {
        path: '/reset-password',
        element: <ResetPasswordPage />,
    },
    {
        path: '',
        element: <MainPage/>,
        errorElement: <ErrorBoundary/>,
        loader: current,
        children: [
            {
                path: '/',
                element: <HomePage />
            },
            {
                path: '/zoneChart',
                element: <ZoneChartPage/>,
                handle: {
                    title: 'Zone Chart',
                },
            },
            {
                path: '/orgChart',
                element: <OrganizationPage/>,
                handle: {
                    title: 'Organization',
                },
            },
            {
                path: '/profile',
                element: <ProfilePage/>,
                handle: {
                    title: 'Profile',
                },
            },
            {
                path: '/people',
                children: [
                    {
                        path: '',
                        element: <PeopleListPage/>,
                        handle: {
                            title: 'People',
                        },
                    },
                    {
                        path: ':personId',
                        element: <PersonDetailPage/>
                    }
                ]
            },

            {
                path: '/zones',
                children: [
                    {
                        path: '',
                        element: <ZonesListPage/>,
                        handle: {
                            title: 'Zones',
                        },
                    },
                    {
                        path: ':zoneId',
                        element: <ZoneDetailPage/>
                    }
                ]
            },

            {
                path: '/teams',
                children: [
                    {
                        path: '',
                        element: <TeamsListPage/>,
                        handle: {
                            title: 'Teams',
                        },
                    },
                    {
                        path: ':teamId',
                        element: <TeamDetailPage/>
                    }
                ]
            },

            {
                path: '/roles',
                children: [
                    {
                        path: '',
                        element: <RolesListPage/>,
                        handle: {
                            title: 'Roles',
                        },
                    },
                    {
                        path: ':roleId',
                        element: <RoleDetailPage/>
                    }
                ]
            },


            {
                path: '/goals',
                children: [
                    {
                        path: '',
                        element: <GoalsListPage/>,
                        handle: {
                            title: 'Goals',
                        },
                    },
                    {
                        path: ':goalId',
                        element: <GoalDetailPage/>
                    }
                ]
            },

            {
                path: '/decisions',
                children: [
                    {
                        path: '',
                        element: <DecisionsListPage/>,
                        handle: {
                            title: 'Decisions',
                        },
                    },
                    {
                        path: ':decisionId',
                        element: <DecisionDetailPage/>
                    }
                ]
            },

            {
                path: '/service-agreements',
                children: [
                    {
                        path: '',
                        element: <ServiceAgreementsListPage/>,
                        handle: {
                            title: 'Service Agreements',
                        },
                    },
                    {
                        path: ':serviceAgreementId',
                        element: <ServiceAgreementDetailPage/>
                    }
                ]
            },

            {
                path: '/admin',
                element: <AdminPage />
            },

            {
                path: '/settings',
                children: [
                    {
                        path: '',
                        element: <SettingsPage/>,
                        handle: {
                            title: 'Settings',
                        },
                    },
                    {
                        path: 'create-template',
                        element: <CreateTemplatePage/>,
                        handle: {
                            title: 'Create A New Template',
                        },
                    },
                    {
                        path: '/settings/templates/:templateId',
                        element: <TemplateDetailPage/>
                    },
                    {
                        path: '/settings/templates/:templateId/edit',
                        element: <EditTemplatePage/>
                    }
                ]
            },

            {
                path: '*',
                element: <Navigate to={'/zoneChart'} replace/>,
            }
        ]
    }
] as RouteObject[]

export default router;
