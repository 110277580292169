import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';

import type {Risk, SliceStatus, Organization, ErrorDetail} from 'types';
import {CLEAR_ORG_ACTION, LOGOUT_ACTION, RootState} from 'store';
import apiClient from 'api';


type RisksState = {
    entities: Risk[],
    status: SliceStatus,
    error: string,
};

export const fetchRisks = createAsyncThunk<Risk[]>('risks/fetch', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    return await apiClient.get(`/org/${state.org.value.id}/risks`);
});

export const deleteRisk = createAsyncThunk<Risk, string, {
    rejectValue: ErrorDetail,
}>('Risk/delete', async (RiskId, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.delete<Risk>(`/org/${state.org.value.id}/risks/${RiskId}`);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

export const createRisk = createAsyncThunk<Risk, Risk, {
    rejectValue: ErrorDetail,
}>('risk/create', async (Risk, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.post(`/org/${state.org.value.id}/risks`, Risk);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

type UpdateRisk = Partial<Risk> & {id: string};
export const updateRisk = createAsyncThunk<Risk, UpdateRisk, {
    rejectValue: ErrorDetail,
}>('risk/update', async (data, thunkAPI) => {
    const state = thunkAPI.getState() as {org: {value: Organization}};
    try {
        return await apiClient.patch(`/org/${state.org.value.id}/risks/${data.id}`, data);
    } catch (e) {
        return thunkAPI.rejectWithValue(e as ErrorDetail);
    }
});

const INITIAL_STATE = {
    entities: [],
    status: 'idle' as RisksState['status'],
    error: ''
};

// noinspection JSUnusedGlobalSymbols
const RiskSlice = createSlice({
    name: 'risks',
    initialState: INITIAL_STATE satisfies RisksState as RisksState,
    reducers: {
        clearError: (state) => {
            state.error = '';
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchRisks.pending, (state: RisksState) => {
                state.status = 'pending';
            })
            .addCase(fetchRisks.fulfilled, (state: RisksState, action: PayloadAction<Risk[]>) => {
                state.status = 'succeeded';
                state.entities = action.payload as Risk[];
            })
            .addCase(fetchRisks.rejected, (state: RisksState, action) => {
                state.status = 'failed';
                //state.error = action.error.message;
                console.log(action);
            })
            .addCase(createRisk.fulfilled, (state: RisksState, action: PayloadAction<Risk|ErrorDetail>) => {
                state.entities.push(action.payload as Risk);
            })
            .addCase(deleteRisk.fulfilled, (state: RisksState, action) => {
                state.entities = state.entities.filter((Risk: Risk) => Risk.id !== action.payload.id);
            })
            .addCase(CLEAR_ORG_ACTION, (state: RisksState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(LOGOUT_ACTION, (state: RisksState) => {
                Object.assign(state, INITIAL_STATE);
            })
            .addCase(updateRisk.fulfilled, (state: RisksState, action: PayloadAction<Risk|ErrorDetail>) => {
                const risk = action.payload as Risk;
                const index = state.entities.findIndex(r => r.id === risk.id);
                state.entities[index] = risk;
            })
    }
});

export const selectRisks = (state: RootState) => state.risks.entities;
export const selectRiskStatus = (state: RootState) => state.risks.status;

export default RiskSlice.reducer;
