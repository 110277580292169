import {Navigate} from 'react-router-dom';
import {usePersonTeams} from 'hooks/useTeams';
import {useCurrentUser} from 'hooks/useCurrentUser';

const FirstTeam = () => {
    const currentUser = useCurrentUser();
    const teams = usePersonTeams(currentUser)

    if (teams.length === 0) {
        return <Navigate to={'/zoneChart'} />
    }

    return (
        <Navigate to={`/teams/${teams[0]['id']}`} />
    );
}

export default FirstTeam;
