import {useCallback} from 'react';
import {AppDispatch, useAppDispatch, useAppSelector} from 'store';

import {useOrg} from './useOrg';
import {getSettings, setSettings} from '../features/settingsSlice';


export function useSettings() {
    const org = useOrg();
    const settings = useAppSelector(getSettings);
    if (!org) return {} as Record<string, string>;
    return settings[org.id] || {} as Record<string, string>;
}

export function useSetSetting() {
    const org = useOrg();
    const dispatch: AppDispatch = useAppDispatch();

    return useCallback(
        (name: string, value: string) => {
            if (org?.id) {
                return dispatch(setSettings({orgId: org.id, name, value}));
            }
        },
        [dispatch, org]
    )
}
