import {Navigate} from 'react-router-dom';
import {useSettings} from 'hooks/useSettings';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {isPersonSystem} from 'utils';
import FirstTeam from './FirstTeam';

const HomePage = () => {
    const currentUser = useCurrentUser();
    const settings = useSettings();
    if (!currentUser) return <Navigate to={'/zoneChart'} />

    let home = settings['home'];
    if (!home) {
        home = isPersonSystem(currentUser) ? 'all-teams' : 'first-team'
    }

    switch (home) {
        case 'org-chart':
            return <Navigate to={'/orgChart'} />
        case 'zone-chart':
            return <Navigate to={'/zoneChart'} />
        case 'all-teams':
            return <Navigate to={'/teams'} />
        case 'first-team':
            return <FirstTeam />;
    }
    return <Navigate to={`/teams/${home}`} />
}

export default HomePage;
