import React from 'react';
import {Card, Typography, Space, Descriptions, DescriptionsProps, App} from 'antd';
import {useCurrentUser} from 'hooks/useCurrentUser';
import {usePeople} from 'hooks/usePeople';
import EditableAvatar from 'components/avatar/EditableAvatar';
import {getDispatchError, hasDispatchError, map} from 'utils';
import type {BaseObject, ObjectPictures, Person} from 'types';
import {AppDispatch, useAppDispatch} from 'store';
import {updatePerson} from 'features/personSlice';
import {setCurrentUser} from 'features/currentUserSlice';
import DefaultHomePageCard from './DefaultHomePageCard';

const ProfilePage = () => {
    const {message} = App.useApp();
    const currentUser = useCurrentUser();

    /* This redundancy forces a re-render on goal data update. */
    const people = map(usePeople());
    const dispatch: AppDispatch = useAppDispatch();

    if (!currentUser) return null;
    const reportsTo = currentUser.reportsTo ? people[currentUser.reportsTo.id] : null;

    const items: DescriptionsProps['items'] = [
        {
            key: 'email',
            label: 'Email',
            children: <Typography.Text>{currentUser.email}</Typography.Text>,
        },
        {
            key: 'firstName',
            label: 'First Name',
            children: <Typography.Text>{currentUser.firstName}</Typography.Text>
        },
        {
            key: 'lastName',
            label: 'Last Name',
            children: <Typography.Text>{currentUser.lastName}</Typography.Text>,
        }
    ];

    if (currentUser.title) {
        items.push({
            key: 'title',
            label: 'Title',
            children: <Typography.Text>{currentUser.title}</Typography.Text>,
        })
    }

    if (reportsTo) {
        items.push({
            key: 'reportsTo',
            label: 'Reports To',
            children: <Typography.Link href={`/people/${reportsTo.id}`}>{reportsTo.name}</Typography.Link>,
        });
    }

    items.push({
        key: 'userType',
        label: 'User Type',
        children: <Typography.Text>{currentUser.userType}</Typography.Text>,
    },)

    async function handleAvatarChange(obj: BaseObject & ObjectPictures) {
        const person = {...currentUser, ...obj} as Person;
        const res = await dispatch(updatePerson(person));
        if (hasDispatchError(res)) {
            await message.error(getDispatchError(res));
            return false;
        }
        dispatch(setCurrentUser(person));
        return true;
    }

    return (
        <Space direction={'vertical'} size={'middle'} style={{width: '100%'}}>
            <Card>
                <Space align={'center'} size={'large'}>
                    <EditableAvatar size={64} obj={currentUser} updateObject={handleAvatarChange}/>
                    <Space direction={'vertical'}>
                        <Typography.Title level={2} style={{margin: 0}}>{currentUser.name}</Typography.Title>
                        {currentUser.title ? <Typography.Text>{currentUser.title}</Typography.Text> : null}
                    </Space>
                </Space>
            </Card>
            <Card>
                <Descriptions size={'small'} items={items} column={1} labelStyle={{alignItems: 'center'}}/>
            </Card>
            <DefaultHomePageCard />
        </Space>
    );
}

export default ProfilePage;
