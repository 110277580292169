import {Card, Typography, Flex, Avatar} from 'antd';
import {UserOutlined} from '@ant-design/icons';

import styles from './Card.module.scss';

import type {Role, Person} from 'types';
import {usePeople} from 'hooks/usePeople';
import {initials, map} from 'utils';
import {useThemeMode} from '../../../hooks/useThemeMode';


const RoleAvatar = (props: {
    person?: Person
}) => {
    const mode = useThemeMode();
    const color = mode === 'dark' ? 'rgb(140, 140, 140)' : 'rgba(0, 0, 0, 0.25)';
    if (!props.person) {
        return <Avatar style={{ border: `1px solid ${color}`, color, backgroundColor: 'transparent'}} icon={<UserOutlined/>} />
    }
    if (props.person.picture_small) {
        return <Avatar src={props.person.picture_small} />
    }
    return <Avatar>{initials(props.person.name)}</Avatar>
}


const RoleCard = (props: {
    role: Role
    onClick?: () => void
}) => {
    const people = map(usePeople());
    const assignee = props.role.assignment?.id ? people[props.role.assignment.id] : undefined;

    return (
        <Typography.Link onClick={props.onClick}>
            <Card className={styles.card}>
                <Flex>
                    <RoleAvatar person={assignee} />
                    <div className='card-info'>
                        {/*
                        <span className='designation'>Team Lead</span>
                        */}
                        <Typography.Title level={3}>{props.role.name}</Typography.Title>
                        <div className='status'>
                            <i></i>
                            <span>Active</span>
                            {/*
                            <label className='checkbox'>
                                <CheckOutlined />
                            </label>
                            */}
                        </div>
                    </div>
                </Flex>
                {/*
                <div className='linkicon'>
                    <MoreOutlined />
                </div>
                */}
            </Card>
        </Typography.Link>
    );
}

export default RoleCard;
